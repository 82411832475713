import { BlockBlobClient } from "@azure/storage-blob";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useFormik } from "formik";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Upload } from "../../icons/upload";
import { createTheme } from "../../theme";
import { convertFileToArrayBuffer } from "../../utils/convert-file-to-array-buffer";
import { generateMD5 } from "../../utils/generate-md5";
import { homePage } from "../../utils/homepage";
import { Languages } from "../../utils/languages";
import { supportedFileTypes } from "../../utils/supported-file-types";
import { typesFile } from "../../utils/types-file";
import type { File } from "../file-dropzone";
import { FileDropzone } from "../file-dropzone";

interface IAudio extends File {
  path?: string;
  name: string;
  lastModified: number;
  webkitRelativePath: string;
  size: number;
  type: string;
  milliseconds: number;
}

interface IParams {
  uselp: string
  formtype: string
}

const apiURL = "https://api.celeste-ai.com";
const appURL = "https://app.celeste-ai.com";

const paramLP = 'uselp';
const paramFormType = 'formtype';

export const Form: FC = () => {
  let durationResend: number = 1;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>();
  const [files, setFiles] = useState<File[]>([]);
  const [fileAudio, setFileAudio] = useState<IAudio>({} as IAudio);
  const [arrayBuffer, setArrayBuffer] = useState<ArrayBuffer | null>(null);
  const [step, setStep] = useState<number>(1);
  const [time, setTime] = useState<number>(60);
  const [codeHash, setCodeHash] = useState<string>();
  const [linkToDash, setLinkToDash] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [userContainer, setUserContainer] = useState<string>("");
  const [isLP, setIsLP] = useState<boolean>(false);
  const [isFinaly, setIsFinaly] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>('1');
  const [checked, setChecked] = useState(false);
  const [audioName, setAudioName] = useState<string>("");
  const [fileFormat, setFileFormat] = useState<string>("");
  const [progressUpload, setProgressUpload] = useState<number>(0);

  useMemo(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const params: IParams = {
      uselp: queryParameters.get(paramLP) ?? 'false',
      formtype: queryParameters.get(paramFormType) ?? '1'
    };
    setIsLP(Boolean(params.uselp));
    setFormType(params.formtype);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      submit: null,
      name: "",
      email: "",
      confirmEmail: "",
      language: "",
      file: "",
      codeEmail: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required("Nome é obrigatório"),
      email: Yup.string()
        .max(255)
        .email("Informe um e-mail válido.")
        .required("E-mail é obrigatório"),
      confirmEmail: Yup.string()
        .max(255)
        .email("Informe um e-mail válido.")
        .required("E-mail é obrigatório"),
      language: Yup.string().required("O idioma é obrigatório"),
      file: Yup.mixed().required("Áudio é obrigatório"),
    }),
    onSubmit: async (): Promise<void> => {
      return;
    },
  });

  const handleDrop = (newFiles: File[]): void => {
    setFiles(newFiles);
    formik.setFieldValue("file", newFiles[0]);
  };

  const handleRemove = (file: File): void => {
    setFiles((prevFiles) =>
      prevFiles.filter((_file) => _file.path !== file.path)
    );
  };

  const handleChangeFileName = (newFileName: string) => {
    if (files[0]) {
      const newFile = new File([files[0]], newFileName, {
        type: files[0].type,
      });
      return newFile;
    }
  };

  const sendToAMQP = useCallback(async () => {
    console.log('sendToAMQP')
    if (!formik.values.language) return;
    if (!audioName || !fileFormat) return;

    const req = await axios.post(
      `${apiURL}/transcription/queue`,
      {
        name: fileAudio.name,
        fileName: encodeURIComponent(audioName),
        fileLanguage: formik.values.language,
        fileDuration: fileAudio.milliseconds,
        fileFormat,
        filePath: `temp/${encodeURIComponent(audioName)}`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (req.status !== 200) {
      setToastMessage("Erro ao enviar arquivo para fila");
    }

    redirectUser(linkToDash);
  }, [
    audioName,
    fileAudio.milliseconds,
    fileAudio.name,
    fileFormat,
    formik.values.language,
    token,
    // params,
    linkToDash
  ]);

  const blobSchema = {
    permission: "r", //read only
    timerange: 1440, //1d
    file: fileAudio && encodeURIComponent(fileAudio.name),
    container: userContainer,
  };

  const handleUploadFile = useCallback(
    async (sasToken: string) => {
      setIsLoading(true);
      const blockBlobClient = new BlockBlobClient(sasToken);
      await blockBlobClient
        .uploadData(arrayBuffer!, {
          tier: "Cool",
          onProgress: (progress) => {
            const bytesUploaded = progress.loadedBytes;
            const totalBytes = fileAudio!.size;
            const blobProgress = Math.round((bytesUploaded / totalBytes) * 100);
            setProgressUpload(blobProgress);
          },
        })
        .then(async () => {
          const AMQP = await sendToAMQP();
          return AMQP;
        })
        .catch((err) => {
          setIsLoading(false);
          setToastMessage("Upload: " + err);
        })
        .finally(() => setIsLoading(false));
    },
    [arrayBuffer, fileAudio, sendToAMQP]
  );

  const handleFileSasToken = useCallback(async () => {
    const formData = {
      container: blobSchema.container,
      path: "temp",
      file: encodeURIComponent(audioName),
    };

    if (!fileAudio || !formik.values.language || !formData) return;

    const req = await axios.post(`${apiURL}/transcription/sas`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (req.status !== 201) {
      setToastMessage("Erro ao obter autorização para upload");
    }

    const sasToken = req.data;
    return handleUploadFile(sasToken);
  }, [
    audioName,
    blobSchema.container,
    fileAudio,
    formik.values.language,
    handleUploadFile,
    token,
  ]);

  const handleStepName = async (newStep: number): Promise<void> => {
    try {
      setIsLoading(true);
      setToastMessage("");

      if (!formik.values.name)
        return setToastMessage("Informe seu nome para continuar.");

      if (newStep === 3 && formik.values.name && formik.values.email) {
        await sendMail();
        return setStep(newStep);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleStepEmail = async (newStep: number): Promise<void> => {
    try {
      setIsLoading(true);
      setToastMessage("");

      const { email, confirmEmail } = formik.values;
      const isEmailEquals = await checkEmailEquals(email, confirmEmail);

      if (!email || !confirmEmail)
        return setToastMessage("Preencha os campos corretamente.");

      if (!isEmailEquals) return setToastMessage("E-mails devem ser iguais.");

      if (newStep === 2) {
        const isAllowedLead = await checkLead();
        if (isAllowedLead) return setStep(newStep);

        return setToastMessage("Usuário já cadastrado.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const checkEmailEquals = async (
    email: string,
    confirmEmail: string
  ): Promise<boolean> => {
    return email.trim() === confirmEmail.trim();
  };

  const checkLead = useCallback(async (): Promise<boolean> => {
    const checkAllowedLead = await axios
      .post(`${apiURL}/lead/check`, {
        email: formik.values.email.toLowerCase(),
      })
      .then((response) => {
        const { isAllowed } = response.data;
        return isAllowed;
      })
      .catch((error) => {
        setToastMessage(error?.response?.data?.error[0]);
        return false;
      });

    return checkAllowedLead;
  }, [formik.values.email]);

  const handleDurationChange = (minutes: number) => {
    setTime(minutes * 60);
  };

  const handleResendCode = async (): Promise<void> => {
    const emailSent = await sendMail();

    if (emailSent) {
      return handleDurationChange((durationResend += 1));
    }

    setToastMessage("Erro ao enviar código por e-mail.");
  };

  const sendMail = useCallback(async (): Promise<boolean> => {
    try {
      setIsLoading(true);
      setToastMessage("");

      const requestSendMail = await axios
        .post(`${apiURL}/mail/send-code`, {
          name: formik.values.name,
          email: formik.values.email.toLowerCase(),
        })
        .then((response) => {
          const hash = response.data;
          setCodeHash(hash);

          return true;
        })
        .catch((error) => {
          setToastMessage(error?.response?.data?.error[0]);
          return false;
        });

      return requestSendMail;
    } finally {
      setIsLoading(false);
    }
  }, [formik.values.email, formik.values.name]);

  const handleStepCode = useCallback(
    async (currentHash: string) => {
      if (currentHash === codeHash) {
        let redirect_url = '';

        try {
          setIsLoading(true);
          setToastMessage("");

          const name = formik.values.name;
          const email = formik.values.email.toLowerCase();

          const dataLeadToken = {
            name,
            email,
          };

          await axios
            .post(`${apiURL}/lead`, dataLeadToken)
            .then(async (response) => {
              const { token, container } = response.data as {
                token: string;
                container: string;
              };

              redirect_url = `${appURL}/login?token=${token}`;
              setLinkToDash(redirect_url);
              setToken(token);
              setUserContainer(container);
            })
            .catch((error) => {
              setToastMessage(error?.response?.data?.error[0]);
            });
        } finally {
          setStep(4);
          setIsFinaly(formType === '2');
          if (formType === '2' && !isLP) {
            redirectUser(redirect_url);
          }
          setIsLoading(false);
        }
      }
    },
    [codeHash, formik.values.email, formik.values.name, formType, isLP]
  );

  useEffect(() => {
    if (files[0]) {
      const audio = document.createElement("audio");
      const currentAudio: File = files[0];
      audio.src = URL.createObjectURL(currentAudio);

      audio.addEventListener("loadedmetadata", () => {
        URL.revokeObjectURL(audio.src);

        const userAudio: IAudio = {
          ...currentAudio,
          path: currentAudio.path,
          name: currentAudio.name,
          lastModified: currentAudio.lastModified,
          webkitRelativePath: currentAudio.webkitRelativePath,
          size: currentAudio.size,
          type: currentAudio.type,
          milliseconds: Math.round(audio.duration * 1000),
        };

        const fName =
          uuidv4().toString().replaceAll("-", "") +
          userAudio.path!.substring(
            userAudio.path!.length - 4,
            userAudio.path!.length
          );

        const fFormat = userAudio.path!.substring(
          userAudio.path!.length - 4,
          userAudio.path!.length
        );

        setAudioName(fName);
        setFileFormat(fFormat);
        setFileAudio(userAudio);

        const currentFile = handleChangeFileName(fName);
        if (supportedFileTypes.includes(fFormat)) {
          return convertFileToArrayBuffer(currentFile as File).then(
            async (fileArrayBuffer) => {
              if (
                fileArrayBuffer === null ||
                fileArrayBuffer.byteLength < 1 ||
                fileArrayBuffer.byteLength > 1073741824 // 1gb
              ) {
                setArrayBuffer(null);
                setToastMessage(
                  "Arquivo excede o tamanho máximo de upload (1 GB)."
                );
              }
              setArrayBuffer(fileArrayBuffer);
            }
          );
        }

        return setToastMessage("Arquivo não suportado.");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    if (step === 3) {
      const timer = setInterval(() => {
        if (time > 0) {
          setTime((prevTime) => prevTime - 1);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [step, time]);

  useEffect(() => {
    let formikCode = String(formik.values.codeEmail);
    let savedCodeHash = String(codeHash);

    if (formikCode.length >= 6 && savedCodeHash.length >= 6) {
      const hashedCode = generateMD5(formik.values.codeEmail);
      handleStepCode(hashedCode);
    }
  }, [codeHash, handleStepCode, formik.values.codeEmail]);

  const redirectUser = (linkDash: string) => window.location.href = linkDash;

  if (isLoading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "16px",
            gap: "25px",
          }}
        >
          <Typography variant="h2" sx={{ fontSize: "32px" }} >
            Processando...
          </Typography>

          {step === 5 && (
            <Typography variant="h3" >
              Já estamos processando o seu arquivo e isso pode levar alguns
              minutos. Mantenha essa janela aberta para concluir o seu upload!
            </Typography>
          )}

          {progressUpload ? (
            <CircularProgress variant="determinate" value={progressUpload} />
          ) : (
            <CircularProgress /> ||
            (progressUpload === 100 && <CircularProgress />)
          )}
        </div>
      </>
    );
  }

  if (isLP && isFinaly) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "16px",
            gap: "25px",
          }}
        >
          <Typography variant="h2" >
            {formType === '1' ? 'Obrigado!' : 'Eba, tudo certo com o seu cadastro!'}
          </Typography>
          <a
            href={linkToDash}
            target="_blank"
            className="btn"
            rel="noreferrer"
          >
            Ir para o dashboard!
          </a>
        </div>
      </>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <span style={{ display: 'none' }}> v2 </span>
      {/* email */}
      {step === 1 && (
        <>
          <Typography variant="h2"> Envie seu primeiro arquivo agora mesmo. </Typography>

          <Typography variant="body1" sx={{ mt: "30px", textAlign: "center" }} >
            Você tem <b>30 minutos</b> gratuitos.
          </Typography>

          <Typography variant="h3"> Teste Grátis! </Typography>

          <TextField
            fullWidth
            label="Digite o seu melhor e-mail"
            name="email"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            fullWidth
            label="Confirme o seu e-mail"
            name="confirmEmail"
            value={formik.values.confirmEmail}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(
              formik.touched.confirmEmail && formik.errors.confirmEmail
            )}
            helperText={
              formik.touched.confirmEmail && formik.errors.confirmEmail
            }
          />

          <LoadingButton
            sx={{ mt: 5 }}
            fullWidth
            size="large"
            type="button"
            variant="contained"
            loading={isLoading}
            onClick={async () => await handleStepEmail(2)}
          >
            Próxima etapa
          </LoadingButton>
        </>
      )}

      {/* nome  */}
      {step === 2 && (
        <>
          <Typography variant="h2" > Continue o seu cadastro </Typography>
          <TextField
            fullWidth
            label="Nome"
            name="name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              label={
                <>
                  Ao usar a Celeste, você aceita os
                  <a href="https://celeste-ai.com/termos-de-uso/" target="_blank" rel="noreferrer"> Termos de Uso </a>
                  e as
                  <a href="https://celeste-ai.com/politica-de-privacidade/" target="_blank" rel="noreferrer"> condições de tratamento dos seus dados.</a>
                </>
              }
            />
          </Box>
          <Typography variant="inherit" sx={{fontSize:12, display: 'block'}}>Você deve ter 18 anos ou mais para se cadastrar.</Typography>

          <LoadingButton
            sx={{ mt: 5 }}
            fullWidth
            size="large"
            type="button"
            variant="contained"
            loading={isLoading}
            onClick={async () => await handleStepName(3)}
            style={{
              fontWeight: "bold",
              textTransform: "initial",
            }}
            disabled={!checked}
          >
            Próxima etapa
          </LoadingButton>
        </>
      )}

      {/* token */}
      {step === 3 && (
        <>
          <Typography variant="h2" mb={2}> Autenticação </Typography>
          <Typography variant="body1" textAlign={"center"}> Um código foi enviado para seu e-mail. </Typography>
          <TextField
            fullWidth
            label="Código"
            placeholder="Cole o código aqui"
            name="codeEmail"
            value={formik.values.codeEmail}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              const containsLetters = /\D/.test(e.target.value);

              if (!containsLetters) {
                formik.handleChange(e);
              }
            }}
            error={Boolean(formik.touched.codeEmail && formik.errors.codeEmail)}
            helperText={formik.touched.codeEmail && formik.errors.codeEmail}
          />

          <Box sx={{ marginTop: 5 }}>
            {time > 0 && (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: createTheme.palette.primary.main,
                    mt: 1,
                    fontSize: 15,
                  }}
                >
                  {`Aguarde ${time} segundos para solicitar um novo código.`}
                </Typography>
              </>
            )}

            <LoadingButton
              sx={{ mt: 5 }}
              fullWidth
              size="large"
              type="button"
              loading={isLoading}
              onClick={async () => await handleResendCode()}
              disabled={Boolean(time !== 0)}
            >
              Não recebi o código
            </LoadingButton>
          </Box>

          <LoadingButton
            sx={{ mt: 3 }}
            fullWidth
            size="large"
            type="button"
            loading={isLoading}
            onClick={() => setStep(1)}
          >
            Quero alterar meu e-mail
          </LoadingButton>
        </>
      )}

      {/* pergunta  */}
      {step === 4 && (
        <>
          <Typography variant="h2" >
            Eba, tudo certo com<br />o seu cadastro!
          </Typography>

          <img alt="Check" src="../images/check.png" className="mt-auto" />

          {formType === '1' && (
            <>
              <Typography variant="body1" my="30px" textAlign={"center"}>
                Escolha uma das opções abaixo:
              </Typography>

              <LoadingButton
                sx={{ mb: 5 }}
                fullWidth
                size="large"
                type="button"
                variant="contained"
                startIcon={<Upload />}
                loading={isLoading}
                onClick={() => setStep(5)}
              >
                Enviar meu arquivo
              </LoadingButton>
            </>
          )}
          <a
            href={linkToDash}
            target="_blank"
            className="btn"
            rel="noreferrer"
          >
            Ir para o dashboard!
          </a>
        </>
      )}

      {/* send transcription */}
      {step === 5 && (
        <>
          <Typography variant="h2" mb={3} >Transcreva com a Celeste!</Typography>
          <Typography variant="body1" mb={5} textAlign={"center"}>
            Envie um áudio ou vídeo e teste agora,<br />você tem <b>30 minutos gratuitos.</b>
          </Typography>

          <Box sx={{ mt: "16px" }} >
            <FileDropzone
              accept={typesFile}
              files={files}
              onDrop={handleDrop}
              onRemove={handleRemove}
              maxFiles={1}
              multiple={false}
              isError={!files}
            />
            {!files && (
              <FormHelperText
                variant="outlined"
                sx={{
                  color: "#d32f2f",
                }}
              >
                O arquivo de áudio ou vídeo é obrigatório
              </FormHelperText>
            )}
          </Box>

          <TextField
            className="celest-form-input-language"
            select
            fullWidth
            sx={{ mt: 2, "& > div div": { display: "flex" } }}
            name="language"
            label="Selecione o idioma do seu arquivo:"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.language}
            error={Boolean(formik.touched.language && formik.errors.language)}
            helperText={formik.touched.language && formik.errors.language}
          >
            {Languages.map((language) => (
              <MenuItem value={language.value} key={language.value}>
                <img
                  style={{ marginRight: "8px" }}
                  width={22}
                  height={22}
                  src={homePage(`flags/${language.icon}.svg`)}
                  alt={language.label}
                />

                {language.label}
              </MenuItem>
            ))}
          </TextField>

          <LoadingButton
            sx={{ mt: 5 }}
            fullWidth
            size="large"
            type="button"
            variant="contained"
            startIcon={<Upload />}
            loading={isLoading}
            onClick={async () => await handleFileSasToken()}
            style={{
              fontWeight: "bold",
              textTransform: "initial",
            }}
          >
            Enviar arquivo
          </LoadingButton>
        </>
      )}

      {!!toastMessage && (
        <Alert
          className="celest-form-input-error"
          severity="error"
          sx={{ mt: 3 }}
        >
          {toastMessage === "User already exists."
            ? "Usuário já cadastrado."
            : toastMessage}
        </Alert>
      )}
    </form>
  );
};
