import { createSvgIcon } from "@mui/material/utils";

export const Upload = createSvgIcon(
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 20C5.6 20 5.25 19.85 4.95 19.55C4.65 19.25 4.5 18.9 4.5 18.5V14.925H6V18.5H19V14.925H20.5V18.5C20.5 18.9 20.35 19.25 20.05 19.55C19.75 19.85 19.4 20 19 20H6ZM11.75 16.175V6.9L8.75 9.9L7.675 8.825L12.5 4L17.325 8.825L16.25 9.9L13.25 6.9V16.175H11.75Z"
      fill="#004068"
    />
  </svg>,
  "Upload"
);
