import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Form } from "./components/form";
import "./style/App.css";
import { createTheme } from "./theme";

function App() {
  return (
    <Box
      className="celest-form-root"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",       
      }}
    >
      <ThemeProvider theme={createTheme}>
        <Form />
      </ThemeProvider>
    </Box>
  );
}

export default App;
