export const productionUrl = "https://celeste.engina.com.br/wordpress/react/build";
export const homePage = (link: string): string => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return `./${link}`;
  }
  return `${productionUrl}/${link}`;
};
