import { createTheme as createMuiTheme } from "@mui/material/styles";

const colorsSystem = {
  primary: "#004068",
  secondary: "#FFB923",
  over: "#0089E5"
}

export const createTheme = createMuiTheme({
  palette: {
    primary: {
      main: colorsSystem.primary,
      "100": colorsSystem.over
    },
    secondary: {
      main: colorsSystem.secondary
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Work Sans",
          fontStyle: "normal",
          color: colorsSystem.primary,
        },
        h2: {
          fontSize: "26px",
          lineHeight: "30px",
          fontWeight: 'bold',
          textAlign: 'center'
        },
        h3: {
          fontSize: "20px",
          fontWeight: 'bold',
          lineHeight: "25px",
          textAlign: 'center'
        },
        body1: {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 400
        },
        body2: {
          fontSize: "17px",
          lineHeight: "20px",
          letterSpacing: "-0.5px",
          color: colorsSystem.over
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "12px",
          lineHeight: "15px",
          display: "inline-block",
          margin: "0 5px",
          "&:hover": {
            textDecoration: "underline",
            cursor: "pointer"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "30px"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Work Sans",
          fontStyle: "normal",
          border: `2px solid ${colorsSystem.primary}`,
          backgroundColor: colorsSystem.secondary,
          borderRadius: "6px",
          padding: "13px 20px",
          fontWeight: "700",
          fontSize: "17px",
          letterSpacing: "0.6px",
          color: colorsSystem.primary,
          textTransform: 'initial',
          "&:hover": {
            backgroundColor: colorsSystem.over,
            color: "#fff"
          }
        },
        text: {
          backgroundColor: "#ccc",
          "&:hover": {
            backgroundColor: "#eee",
            color: colorsSystem.primary
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: `2px solid ${colorsSystem.primary}`,
          borderRadius: "8px"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: colorsSystem.primary
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontFamily: "Work Sans",
          fontStyle: "normal",
          color: colorsSystem.primary,
          borderWidth: "2px",
          borderColor: colorsSystem.primary,
          borderRadius: "8px"
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "30px"
        }
      }
    }
  }
});
