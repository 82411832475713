/* eslint-disable */

import {
  Alert,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import type { FC } from "react";
import type { DropzoneOptions, FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { Duplicate as DuplicateIcon } from "../icons/duplicate";
import { UploadPlus } from "../icons/upload-plus";
import { X as XIcon } from "../icons/x";
import { bytesToSize } from "../utils/bytes-to-size";

export type File = FileWithPath;

interface FileDropzoneProps extends DropzoneOptions {
  files?: File[];
  onRemove?: (file: File) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
  isError?: boolean;
}

export const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const {
    // Own props
    isError = false,
    files = [],
    onRemove,
    onRemoveAll,
    onUpload,
    // DropzoneOptions props
    accept,
    disabled,
    getFilesFromEvent,
    maxSize,
    minSize,
    maxFiles,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onFileDialogOpen,
    useFsAccessApi,
    autoFocus,
    preventDropOnDocument,
    noClick,
    noKeyboard,
    noDrag,
    noDragEventsBubbling,
    onError,
    validator,
    ...other
  } = props;

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept,
      maxFiles,
      maxSize,
      minSize,
      onDrop,
    });

  return (
    <div {...other}>
      {files.length === 0 && (<>
        <Box
          sx={{
            position: "relative",
            alignItems: "center",
            border: "2px",
            borderRadius: "8px",
            borderStyle: "solid",
            borderColor: isError ? "#d32f2f" : "#004068",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            outline: "none",
            p: "16px",
            ...(isDragActive && {
              backgroundColor: "#d0e0e366",
              opacity: 0.8,
              borderStyle: "dashed",
            }),
            "&:hover": {
              backgroundColor: "action.hover",
              cursor: "pointer",
              opacity: 0.5,
            },
            "> label": {
              width: "100%",
              display: "block"
            },
          }}
        // {...getRootProps()}
        >

          <input type="file" id="fileUpload" {...getInputProps()} />
          <label htmlFor="fileUpload">
            <Box width="100%" display="flex" sx={{ justifyContent: "space-between" }}>
              <Box>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={isError ? "#d32f2f" : "#1A2027"}
                  textAlign={"left"}
                >
                  Carregue um arquivo
                </Typography>
                <Typography
                  component="p"
                  variant="caption"
                  sx={{
                    marginTop: "4px",
                    lineHeight: "16px",
                    color: isError ? "#d32f2f" : "#878787",
                    fontWeight: 400,
                    textAlign: "center"
                  }}
                >
                  do seu dispositivo para transcrevermos
                </Typography>
              </Box>
              <Box><UploadPlus viewBox="0 0 32 32" fontSize="large" /></Box>
            </Box>
          </label>
        </Box>
      </>)}

      {fileRejections.length > 0 && (
        <Alert severity="error" sx={{ mt: 2, borderRadius: 2 }}>
          Selecione um arquivo de áudio ou vídeo.
        </Alert>
      )}

      {files.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <List>
            {files.map((file) => (
              <ListItem
                key={file.path}
                sx={{
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 1,
                  "& + &": {
                    mt: 1,
                  },
                }}
              >
                <ListItemIcon>
                  <DuplicateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    color: "textPrimary",
                    variant: "subtitle2",
                  }}
                  secondary={bytesToSize(file.size)}
                />
                <Tooltip title="Remove">
                  <IconButton edge="end" onClick={() => onRemove?.(file)}>
                    <XIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </div>
  );
};

const customAceppt: any = PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))

FileDropzone.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  accept: customAceppt,
  disabled: PropTypes.bool,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onError: PropTypes.func,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
};
