export const typesFile = {
    "audio/*": [
      ".3ga",
      ".8svx",
      ".aac",
      ".ac3",
      ".aif",
      ".aiff",
      ".alac",
      ".amr",
      ".ape",
      ".au",
      ".dss",
      ".flac",
      ".flv",
      ".m4a",
      ".m4b",
      ".m4p",
      ".m4r",
      ".mp3",
      ".mp4",
      ".mpga",
      ".ogg",
      ".oga",
      ".mogg",
      ".opus",
      ".qcp",
      ".tta",
      ".voc",
      ".wav",
      ".wma",
      ".wv"
    ],
    "video/*": [
      ".3ga",
      ".8svx",
      ".aac",
      ".ac3",
      ".aif",
      ".aiff",
      ".alac",
      ".amr",
      ".ape",
      ".au",
      ".dss",
      ".flac",
      ".flv",
      ".m4a",
      ".m4b",
      ".m4p",
      ".m4r",
      ".mp3",
      ".mp4",
      ".mpga",
      ".ogg",
      ".oga",
      ".mogg",
      ".opus",
      ".qcp",
      ".tta",
      ".voc",
      ".wav",
      ".wma",
      ".wv"
    ]
  } 