export const Languages = [
  {
    label: "Português",
    icon: "Portuguese",
    value: "pt",
  },
  {
    label: "Inglês",
    icon: "English",
    value: "en",
  },
  {
    label: "Espanhol",
    icon: "Spanish",
    value: "es",
  },
  {
    label: "Francês",
    icon: "French",
    value: "fr",
  },
  {
    label: "Alemão",
    icon: "German",
    value: "de",
  },
  {
    label: "Italiano",
    icon: "Italian",
    value: "it",
  },
];
