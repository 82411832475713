import { createSvgIcon } from "@mui/material/utils";

export const UploadPlus = createSvgIcon(
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#0089E5" />
    <path
      d="M15.25 23V16.75H9V15.25H15.25V9H16.75V15.25H23V16.75H16.75V23H15.25Z"
      fill="white"
    />
  </svg>,
  "upload-plus",
  
);
